import axios from 'axios';
import { Module, VuexModule, Mutation, Action, getModule } from 'vuex-module-decorators';
import Vue from 'vue';
import { GetHistoryBySearchRequest, IRunningJob, IJobDescription, ISearchQuery } from '@/utils/api/SchedulerApiClient';
import Apis from '@/utils/api/Apis';

@Module({ name: 'history', stateFactory: true, namespaced: true })
export default class Job extends VuexModule {

    public lastRunningJobs: {[jobId: string]: {[mode: string]: IRunningJob }} = {};
    
    public loadedJobs: {[runningJobId: string]: IRunningJob } = {};

    public newestCreated: Date | null = null;

    @Mutation
    public lastRunningJobsCommit(args: IRunningJob[]) {
        for(let job of args) {
            let jobGroup: {[mode: string]: IRunningJob } = this.lastRunningJobs[job.configuration.id];
            if(!jobGroup) {
                jobGroup = {};
                Vue.set(this.lastRunningJobs, job.configuration.id, jobGroup);
            }
            Vue.set(jobGroup, job.jobDetailsKey || '', job);
        }
    }

    @Mutation
    public setRunningJobCommit(args: {job: IRunningJob, latest: boolean}) {
        Vue.set(this.loadedJobs, args.job.id, args.job);
        if(args.latest) {
            let jobGroup: {[mode: string]: IRunningJob } = this.lastRunningJobs[args.job.configuration.id];
            if(!jobGroup) {
                jobGroup = {};
                Vue.set(this.lastRunningJobs, args.job.configuration.id, jobGroup);
            }
            Vue.set(jobGroup, args.job.jobDetailsKey || '', args.job);
            var date = new Date(args.job.created);
            if(!this.newestCreated || this.newestCreated.getTime() < date.getTime()) {
                Vue.set(this, 'newestCreated', date);
            }
        }
    
    }

    @Action
    public setRunningJob(args: {job: IRunningJob, latest: boolean}) {
        this.setRunningJobCommit(args);
    }


    @Action({rawError:true})
    public async refreshAll() {
        const jobs = <IJobDescription[]>this.context.rootGetters['job/jobs'];

        // TODO: make this an API call when I have NSwag :)
        var result = await Apis.schedulerAxios.get('/scheduler/State/GetLastRunningModes');
        var data = JSON.parse(result.data);
        for(let key in data.runningJobs) {
            this.lastRunningJobsCommit(data.runningJobs[key] || []);

        }
    }

    @Action
    public async refreshJob(args: {jobId: string}) {
        const result = await Apis.schedulerApi.getLastRunningModesByJobId(args.jobId);
        this.lastRunningJobsCommit(result.runningJobs || []);
    }

    @Action
    public async loadJob(args: { runningJobId: string }) {
        const result = await Apis.schedulerApi.getHistoryBySearch(<GetHistoryBySearchRequest>{
            search: <ISearchQuery>{
                type: "equals",
                field: "id",
                value: args.runningJobId
            },
            take: 1,
        });
        if(result.items?.length == 1) {
            this.setRunningJobCommit({job: result.items[0].item, latest: false });
        }
    }
}
